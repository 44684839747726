import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { shortenDateString } from 'utils/date';
import { truncateText } from 'utils/text';
import { ArrowRightIcon, ArrowRightHoverIcon } from 'components/Brand/Icons';
import headsetIcon from 'images/headset.svg';
import { Button, Container } from './styles';

const ToolboxSplashUpcomingCard = ({ post, sidebar }) => {
  const [cardHoverPost, setCardHover] = useState(false);

  return (
    <Container
      bordered={false}
      onMouseEnter={() => {
        setCardHover(post);
      }}
      onMouseLeave={() => {
        setCardHover(null);
      }}
      onClick={() => navigate(post.seo.pageUrl)}
    >
      <div className="toolbox-detail-upcoming--inner-container">
        {!sidebar && (
          <div className="toolbox-detail-upcoming--icon-container">
            <img src={headsetIcon} className="toolbox-detail-upcoming--icon" alt="ico-headset" />
          </div>
        )}
        <div className="toolbox-detail-upcoming--text-container">
          <p className="toolbox-detail-upcoming--title-container">
            {shortenDateString(post.webinarUTCDate)} @ {post.webinarTime} PT
          </p>
          <p className="toolbox-detail-upcoming--title font-bold">
            {truncateText(post.webinarTitle, 90)}
          </p>
          <Button>
            Register Now
            {cardHoverPost !== post ? <ArrowRightIcon /> : <ArrowRightHoverIcon />}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ToolboxSplashUpcomingCard;
